<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.mrpProgramInfo.name }} ({{ this.mrpProgramInfo.topicTitle }})</h1>
      <BannerAid :bannerLink="`topics|${mrpSelectedProgramId}|${mrpSelectedCategory}|${mrpSelectedTopicId}`" />
      <p class="description">{{ this.mrpProgramInfo.topicDescription }}</p>
    </header>
    <b-container class="tiles">
      <b-row class="tile-row">
        <b-col sm="4" xs="12" class="mb-3">
          <b-form-select
            id="sort-select"
            name="sort-select"
            title="Sort"
            v-model="topicSort"
            :options="topicSortOptions"
            class="form-control"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="6" xs="12" class="tile-col mb-5" v-for="(obj, index) in topics" :key="index">
          <card
            :category="obj.category"
            :itemName="obj.itemName"
            :itemId="obj.itemId"
            :itemDescription="obj.itemDescription"
            :btnText="obj.btnText"
            @set_item="setTopicAndRedirect($event)"
          >
            <svg-program-resources class="icon"></svg-program-resources>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BannerAid from '@/components/BannerAid.vue'
import Card from './CardMRPLp.vue'
import ProgramResources from '@/assets/svgs/program-resources.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'mrp-topics',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      topicSort: 'TopicName',
      topicSortOptions: [
        {
          value: 'TopicName',
          text: '', // 'Name'
        },
        {
          value: 'TopicUpdated',
          text: '', // 'Most Recent'
        },
      ],
    }
  },
  components: {
    BannerAid: BannerAid,
    card: Card,
    svgProgramResources: ProgramResources,
  },
  methods: {
    ...mapActions({
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPSelectedTopicId: 'mrp/setMRPSelectedTopicId',
    }),
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.loadMRPProgramInfo({
          lang: this.prefCulture,
          restype: 'topics',
          programid: this.mrpSelectedProgramId,
        }),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    setTopicAndRedirect(data) {
      this.setMRPSelectedTopicId(data.itemId)
      this.$router.push({ name: 'mrp-topic-detail' })
    },
    sortTheArray(listingArray, sortOn, makeDesc) {
      let s1 = makeDesc ? -1 : 1
      let s2 = makeDesc ? 1 : -1
      return listingArray.sort((a, b) => (a[sortOn] > b[sortOn] ? s1 : s2))
    },
    sortChanged(data) {
      this.topicSort = data.sortBy
    },
    loadTranslations() {
      this.topicSortOptions = [
        {
          value: 'TopicName',
          text: this.translations.tcName,
        },
        {
          value: 'TopicUpdated',
          text: this.translations.tcMostRecent,
        },
      ]
    },
  },
  computed: {
    ...mapGetters({
      mrpProgramInfo: 'mrp/mrpProgramInfo',
      mrpSelectedCategory: 'mrp/mrpSelectedCategory',
      mrpSelectedProgramId: 'mrp/mrpSelectedProgramId',
      mrpSelectedTopicId: 'mrp/mrpSelectedTopicId',
      prefCulture: 'user/userPreferredCulture',
    }),
    topics() {
      this.sortTheArray(this.mrpProgramInfo.topicListing, this.topicSort, this.topicSort.includes('Updated'))
      let topics = this.mrpProgramInfo.topicListing.map((t) => {
        return {
          category: 'topic',
          itemId: t.TopicId,
          itemName: t.TopicName,
          itemDescription: t.TopicDescription,
          btnText: t.TopicButtonText,
        }
      })

      return topics
    },
  },
  async created() {
    //need a selected mrp program id
    if (!this.mrpSelectedProgramId) {
      this.$router.push('/resources/media-portal/')
    }
    await Promise.all([this.getViewTranslations(), this.pageLoad()]).then(() => {
      this.loadTranslations()
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  padding-bottom: 20px !important;
  p {
    text-align: center;
    margin-top: 20px;
  }
}

.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;

  select.form-control {
    padding: 6px 28px 6px 12px;
  }
  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    .tile-col-card {
      flex-grow: 2;
    }
  }
}
</style>
